import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Title from "../../components/title"
import * as styles from "./start-project.module.scss"

export default function StartProjectThankYouPage({ ...props }) {
  return (
    <>
      <Layout>
        <Seo title="Thank you" />
        <section className={styles.thankYou}>
          <header>
            <Title headingLevel="h1">Thank you!</Title>
            <Title headingLevel="p">
              We will get back to you <strong>within 24 hours.</strong>
            </Title>
          </header>
        </section>
      </Layout>
    </>
  )
}
